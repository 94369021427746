import React from 'react';
import * as NumberAbbrevation from 'number-abbreviate';

import PlaceholderImage from '../../../assets/inventory-placeholder.png';

import { INVENTORY_DETAILS } from '../../../constants/routes';

// Styles
import './asset-quick.scss';

const NumAbbr = new NumberAbbrevation(['K', 'M', 'B', 'T']);

export default ({ authUser, quickViewItem }) => {
  return (
    <div className="asset-quick-container">
      {quickViewItem.map((item) => (
        <article className="media" key={item.id}>
          <figure className="media-left">
            <img
              className="asset-quick-img"
              src={item.data.thumbnail ? item.data.thumbnail : PlaceholderImage}
              alt="img"
            />
          </figure>
          <div className="media-content">
            <p>
              <strong>Facing {item.data.direction}</strong>
            </p>
            {item.data.averageDailyImpressions ? (
              <p>
                - {NumAbbr.abbreviate(item.data.averageDailyImpressions, 1)}{' '}
                Impressions / Day
              </p>
            ) : null}

            {authUser && item.data.pricing && item.data.pricing.dailyRate ? (
              <p>- {`$${item.data.pricing.dailyRate}`} / Day</p>
            ) : null}

            <div className="asset-quick-link">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${INVENTORY_DETAILS}/${item.id}`}
              >
                <div className="btn-primary asset-quick-link__action">
                  View Details
                </div>
              </a>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};
