import React, { useContext } from 'react';

import { withFirebase } from '../../../utils/Firebase';
import { AuthUserContext } from '../../../utils/Session';

import InputRange from 'react-input-range';
import FilterPill from '../../atoms/FilterPill/filter-pill';

import { assetTypes } from '../../../models/assetTypes';

import _ from 'lodash';
import './filter-pills-form.scss';

const FilterPillsForm = ({
  supplierDocs,
  supplierSelected,
  supplier,
  firebase,
  values,
  previousFilters,
  setFieldValue,
  onSubmit,
}) => {
  const authUser = useContext(AuthUserContext);
  const emptyState = {
    assetTypes: [],
    suppliers: supplierSelected ? [supplier] : [],
    budget: undefined,
  };

  const clearFilter = (filter) => {
    firebase.useAnalytics(`${filter}_filter_cleared`, {
      user: authUser ? authUser.email : null,
      selectedType: values[filter],
    });
    setFieldValue(filter, emptyState[filter]);
  };

  const isSelected = (filter) => {
    return !_.isEqual(values[filter], emptyState[filter]);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="filter-pills-container">
        <div className="filter-pills-container__instance">
          <FilterPill
            firebase={firebase}
            authUser={authUser || true}
            buttonTitle="Type"
            contentTitle="Choose Asset Type"
            dropDownID="assetTypes"
            clear={() => clearFilter('assetTypes')}
            onSubmit={onSubmit}
            isSelected={isSelected('assetTypes')}
            type="checkbox"
            value={values.assetTypes}
            prevFilter={previousFilters ? previousFilters['assetTypes'] : null}
          >
            {Object.keys(assetTypes).map((type) => (
              <div
                className="filter-pills-container__instance__field"
                key={type}
              >
                <input
                  id={assetTypes[type].selector}
                  name="assetTypes"
                  type="checkbox"
                  checked={values.assetTypes.includes(type)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const temp = [...values.assetTypes, type];
                      setFieldValue(e.target.name, temp);
                    } else {
                      const temp = values.assetTypes.filter(
                        (element) => element !== type,
                      );
                      setFieldValue(e.target.name, temp);
                    }
                  }}
                />
                <label htmlFor={assetTypes[type].selector}>
                  {assetTypes[type].displayText}
                </label>
              </div>
            ))}
          </FilterPill>
        </div>
        {!supplier ? (
          <div className="filter-pills-container__instance">
            <FilterPill
              firebase={firebase}
              authUser={authUser || true}
              buttonTitle="Supplier"
              contentTitle="Choose Supplier"
              dropDownID="supplier"
              clear={() => clearFilter('suppliers')}
              onSubmit={onSubmit}
              isSelected={isSelected('suppliers')}
              type="checkbox"
              value={values.suppliers}
              prevFilter={previousFilters ? previousFilters['suppliers'] : null}
            >
              {Object.keys(supplierDocs).map((supplier) => (
                <div
                  className="filter-pills-container__instance__field"
                  key={supplier}
                >
                  <input
                    id={supplier}
                    name="suppliers"
                    type="checkbox"
                    checked={values.suppliers.includes(supplier)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const temp = [...values.suppliers, supplier];
                        setFieldValue(e.target.name, temp);
                      } else {
                        const temp = values.suppliers.filter(
                          (element) => element !== supplier,
                        );
                        setFieldValue(e.target.name, temp);
                      }
                    }}
                  />
                  <label htmlFor={supplier}>
                    {supplierDocs[supplier].displayText}
                  </label>
                </div>
              ))}
            </FilterPill>
          </div>
        ) : null}
        <div className="filter-pills-container__instance">
          <FilterPill
            firebase={firebase}
            authUser={authUser}
            buttonTitle="Budget"
            contentTitle="Set Budget"
            dropDownID="budget"
            clear={() => clearFilter('budget')}
            onSubmit={onSubmit}
            isSelected={isSelected('budget')}
            type="price"
            value={values.budget}
            prevFilter={previousFilters ? previousFilters['budget'] : null}
          >
            <div className="filter-pills-container__instance__range-field">
              <InputRange
                minValue={0}
                maxValue={500}
                step={5}
                value={values.budget || 0}
                onChange={(value) => {
                  setFieldValue('budget', value);
                }}
              />
              <div className="range-value has-text-centered">
                Up to
                <span className="has-text-weight-medium">
                  {' '}
                  ${values.budget} CAD{' '}
                </span>
                / day
              </div>
            </div>
          </FilterPill>
        </div>
      </div>
    </form>
  );
};

export default withFirebase(FilterPillsForm);
