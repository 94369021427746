import React from 'react';
import ContentLoader from 'react-content-loader';

const FilterPillsLoader = () => (
  <ContentLoader
    speed={2}
    width={385}
    height={80}
    viewBox="0 0 385 80"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="20" y="30" rx="20" ry="20" width="80" height="40" />
    <rect x="110" y="30" rx="20" ry="20" width="70" height="40" />
    <rect x="190" y="30" rx="20" ry="20" width="100" height="40" />
  </ContentLoader>
);

export default FilterPillsLoader;
