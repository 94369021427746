import React from 'react';
import Button from '../../atoms/Button/button';

import './pagination-nav-footer.scss';

export default (props) => {
  return (
    <div className="pagination-nav-footer">
      <Button
        disabled={props.isPrevDisabled}
        onClick={props.onPrev}
        icon="fa-caret-left"
      />
      <div>
        Showing {props.startIndex} - {props.endIndex} of {props.totalSize} items
      </div>
      <Button
        disabled={props.isNextDisabled}
        onClick={props.onNext}
        icon="fa-caret-right"
      />
    </div>
  );
};
