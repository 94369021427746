import React from 'react';

import Layout from '../utils/layout';
import { withFirebase } from '../utils/Firebase';
import { globalHistory, Router } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';

import Inventories from '../components/scenes/Inventories/inventories';

const InventoriesPage = withFirebase(Inventories);
export default () => {
  return (
    <Layout>
      <Router basepath="/">
        <QueryParamProvider {...{ path: '/' }} reachHistory={globalHistory}>
          <InventoriesPage path="/inventories" />
          <InventoriesPage path="/inventories/:supplier" />
        </QueryParamProvider>
      </Router>
    </Layout>
  );
};
