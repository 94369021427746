import React, { useContext, useRef, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import * as fb from 'firebase/app';
import 'firebase/firestore';
import { distance, centroid, polygon } from '@turf/turf';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
  BooleanParam,
} from 'use-query-params';

import './inventories.scss';

import { AuthUserContext } from '../../../utils/Session';
import { ListItemContext } from '../../../utils/Contexts/list-item-context';
import { MapContext } from '../../../utils/Contexts/map-context';

import DashboardTemplate from '../../organisms/DashboardTemplate/dashboard-template';
import ListPanel from '../../organisms/ListPanel/list-panel';
import InventoryMap from '../../organisms/InventoryMap/inventory-map';
import ResponseHandler from '../../molecules/ResponseHandler/response-handler';

import { supplierDocs } from '../../../models/suppliers';
import marketAreas from '../../../models/marketAreas';

import { SIGN_IN, SIGN_UP } from '../../../constants/routes';

import _ from 'lodash';

const ONE_MIN_IN_MS = 60000;

const INVENTORY_LIST_COLUMN_ID = 'inventory-list';
const PAGINATION_CHUNK = 50;
const PAGINATION_TRANSITION_DURATION = 500;
const MIN_POLYGON_POINTS = 4;

let inventories = [];
let startIndex = 0;
let endIndex = 0;
let centroidCoordinates;

// This is for accessing geocoder's input for analytics purpose
const searchedLocation = {
  input: undefined,
};

const Inventories = ({ supplier, firebase }) => {
  const authUser = useContext(AuthUserContext);
  const isMountRef = useRef(true);
  const map = useRef(null);
  const geocoderContainer = useRef(null);
  const supplierSelected =
    supplier && Object.keys(supplierDocs).includes(supplier);

  if (typeof window !== 'undefined') {
    if (supplierSelected) localStorage.setItem('supplier', supplier);
    else localStorage.removeItem('supplier', supplier);
  }

  const [currentCoordinates, setCurrentCoordinates] = useState({
    latitude: 49.89576299999999,
    longitude: -97.13926200000009,
    radius: 9,
  });
  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [state, updateState] = useState(); // eslint-disable-line
  const [showInventories, setShowInventories] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState([]);
  const [highlightedItem, setHighlightedItem] = useState(undefined);
  const [quickViewItem, setQuickViewItem] = useState(undefined);
  const [previousFilters, setPreviousFilters] = useState();
  const [pinnedLocations, setPinnedLocations] = useState([]);
  const [initFirebase, setInitFirebase] = useState(false);
  const [response, setResponse] = useState(undefined);

  const [filters, setFilters] = useQueryParams({
    budget: NumberParam,
    market: StringParam,
    assetTypes: ArrayParam,
    suppliers: ArrayParam,
    nearbySearch: BooleanParam,
  });

  const getAvailableMarkets = () => {
    if (supplierSelected) {
      const marketNames = supplierDocs[supplier].markets;
      return marketNames.map((marketName) => marketAreas[marketName]);
    } else if (filters.suppliers) {
      const rawSelectedSuppliersMarketNames = filters.suppliers.map(
        (supplierName) => supplierDocs[supplierName].markets,
      );
      const cleanSelectedSuppliersMarketNames = _.uniq(
        _.flatten(rawSelectedSuppliersMarketNames),
      );
      return cleanSelectedSuppliersMarketNames.map(
        (marketName) => marketAreas[marketName],
      );
    } else {
      const rawAllMarketNames = Object.values(supplierDocs).map(
        (supplier) => supplier.markets,
      );
      const cleanAllMarketNames = _.uniq(_.flatten(rawAllMarketNames));
      return cleanAllMarketNames.map((marketName) => marketAreas[marketName]);
    }
  };

  const clearSearch = () => {
    inventories.splice(0, inventories.length);
    setCurrentPage([]);
    startIndex = 0;
    endIndex = 0;
  };

  const applyFilters = (newFilters, updateType) => {
    setFilters(newFilters, updateType);
    updateState({});
  };

  const getInventories = async () => {
    if (loading && !isMountRef.current) {
      return;
    }
    if (inventories) {
      clearSearch();
      setLoading(true);
    }
    const queries = await Promise.all(aggreagateSearchAreas());
    const query = queries.reduce(
      (total, supplierQuery) => total.concat(supplierQuery.docs),
      [],
    );

    if (!query.length) {
      firebase.useAnalytics('no_result_on_search', {
        user: authUser ? authUser.email : null,
        filters,
        searchedLocationInput: searchedLocation.input,
      });
    }

    //handle duplicate from overlapping radius range
    _.uniqBy(query, 'id').forEach((doc) =>
      inventories.push({ id: doc.id, data: doc.data() }),
    );

    // postprocessing budget filter due to range query limitation
    if (filters.budget && filters.budget > 0) {
      inventories = inventories.filter(
        (inventory) => inventory.data.pricing.dailyRate <= filters.budget,
      );
    }

    inventories.sort(distanceComparator);
    updateList(startIndex);
    isMountRef.current = false;
    return setLoading(false);
  };

  const computeCentroid = (markers) => {
    if (!markers.length) {
    } else if (markers.length < MIN_POLYGON_POINTS) {
      centroidCoordinates = [
        markers[0].data.coordinates.longitude,
        markers[0].data.coordinates.latitude,
      ];
    } else {
      let markerCoordinates = [];
      markers.map((marker) =>
        markerCoordinates.push([
          marker.data.coordinates.longitude,
          marker.data.coordinates.latitude,
        ]),
      );
      const markerPolygon = polygon([
        [...markerCoordinates, markerCoordinates[0]],
      ]);
      const center = centroid(markerPolygon);
      centroidCoordinates = center.geometry.coordinates;
    }
  };

  const adjustCurrentViewport = (markers) => {
    computeCentroid(markers);

    map.current.props.moveTo({
      center: [centroidCoordinates[0], centroidCoordinates[1]],
      zoom: map.current.props.zoom,
      isTransition: false,
      showInventories: true,
      transitionDuration: PAGINATION_TRANSITION_DURATION,
      flyToInterpolator: false,
    });
    setShowInventories(true);
  };

  const queryWithFilters = () => {
    let queryBuilder = firebase.geoinventories();
    if (filters.assetTypes) {
      queryBuilder = queryBuilder.where('type', 'in', filters.assetTypes);
    }
    if (filters.market && filters.nearbySearch === false) {
      queryBuilder = queryBuilder.where(
        'city',
        '==',
        marketAreas[filters.market].city,
      );
      if (filters.budget && filters.budget > 0) {
        queryBuilder = queryBuilder.where(
          'pricing.dailyRate',
          '<=',
          filters.budget,
        );
      }
    }
    return queryBuilder;
  };

  //Aggregate queries for selected pins
  const aggreagateSearchAreas = () => {
    if (pinnedLocations.length) {
      return _.flatten(
        pinnedLocations.map((pin) => {
          return queryWithFiltersBySuppliers().map((q) => {
            return q
              .near({
                center: new fb.firestore.GeoPoint(pin.latitude, pin.longitude),
                radius: Number(pin.radius),
              })
              .get();
          });
        }),
      );
    } else {
      return queryWithFiltersBySuppliers().map((q) => {
        return q
          .near({
            center: new fb.firestore.GeoPoint(
              currentCoordinates.latitude,
              currentCoordinates.longitude,
            ),
            radius: currentCoordinates.radius,
          })
          .get();
      });
    }
  };

  const queryWithFiltersBySuppliers = () => {
    if (supplierSelected) {
      return [queryWithFilters().where('supplier.name', '==', supplier)];
    }
    if (filters.suppliers) {
      return filters.suppliers.map((supplier) => {
        return queryWithFilters().where('supplier.name', '==', supplier);
      });
    } else {
      return [queryWithFilters()];
    }
  };

  const distanceComparator = (a, b) => {
    const queryLocation =
      filters.nearbySearch === false && filters.market
        ? marketAreas[filters.market].coordinates
        : currentCoordinates;

    const distanceA = distance(
      [queryLocation.longitude, queryLocation.latitude],
      [a.data.coordinates.longitude, a.data.coordinates.latitude],
    );
    const distanceB = distance(
      [queryLocation.longitude, queryLocation.latitude],
      [b.data.coordinates.longitude, b.data.coordinates.latitude],
    );

    return distanceA - distanceB;
  };

  let updateList = (index) => {
    if (inventories.length < index + PAGINATION_CHUNK) {
      endIndex = inventories.length;
    } else {
      endIndex = index + PAGINATION_CHUNK;
    }
    let displayedInventories = inventories.slice(index, endIndex);
    computeCentroid(displayedInventories);
    setCurrentPage(displayedInventories);
  };

  const scrollListToTop = () => {
    document.getElementById(INVENTORY_LIST_COLUMN_ID).scrollTop = 0;
  };

  const loadPrevList = () => {
    firebase.useAnalytics('inventories_previous_page_clicked', {
      user: authUser ? authUser.email : null,
    });
    if (startIndex === 0) {
      return;
    }

    scrollListToTop();
    startIndex -= PAGINATION_CHUNK;
    endIndex -= currentPage.length;

    let displayedInventories = inventories.slice(startIndex, endIndex);
    adjustCurrentViewport(displayedInventories);
    setCurrentPage(displayedInventories);
  };

  const loadNextList = () => {
    firebase.useAnalytics('inventories_next_page_clicked', {
      user: authUser ? authUser.email : null,
    });
    if (endIndex === inventories.length) {
      return;
    } else if (inventories.length < endIndex + PAGINATION_CHUNK) {
      endIndex = inventories.length;
    } else {
      endIndex += PAGINATION_CHUNK;
    }

    scrollListToTop();
    startIndex += PAGINATION_CHUNK;

    let displayedInventories = inventories.slice(startIndex, endIndex);
    adjustCurrentViewport(displayedInventories);
    setCurrentPage(displayedInventories);
  };

  const highlightItem = (id) => setHighlightedItem(id);
  const clearHighlightItem = (e) => setHighlightedItem(undefined);
  const formatItemId = (lat, lng) => `${lat}_${lng}`;
  const handleSearchedLocationResult = (placeName) =>
    (searchedLocation.input = placeName);

  const SignInMissingOutClickHandler = () => {
    firebase.useAnalytics('missing_out_dlg_signin_clicked');
    return navigate(SIGN_IN);
  };
  const SignUpMissingOutClickHandler = () => {
    firebase.useAnalytics('missing_out_dlg_signup_clicked');
    return navigate(SIGN_UP);
  };

  const missingOutMessageHandler = () => {
    if (!authUser) {
      setResponse({
        type: 'info',
        title: 'You are missing out!',
        message: (
          <div>
            <p className="message__p">
              It seems you are not logged in. To take advantage of all the
              features of Adsight, please
            </p>
            <button
              className="message__button"
              onClick={SignInMissingOutClickHandler}
            >
              Sign in
            </button>
            <p className="message__p">or</p>
            <button
              className="message__button"
              onClick={SignUpMissingOutClickHandler}
            >
              Sign up
            </button>
            <p className="message__p">
              for FREE if you don't have an account yet.
            </p>
          </div>
        ),
      });
    }
  };

  const deleteMessage = () => setResponse(undefined);

  useEffect(() => {
    const showResponseDelay =
      firebase && !initFirebase
        ? window.setTimeout(missingOutMessageHandler, ONE_MIN_IN_MS)
        : undefined;

    if (firebase && !initFirebase) {
      if (supplierSelected) {
        firebase.useAnalytics('whitelabled_inventories_viewed', {
          supplier,
          user: authUser ? authUser.email : null,
        });
      }
      setInitFirebase(true);
    }

    return () => {
      showResponseDelay && clearTimeout(showResponseDelay);
    };
  }, [firebase]);

  const updateAvailableMarkets = () => {
    setAvailableMarkets(getAvailableMarkets);
  };

  useEffect(updateAvailableMarkets, [filters.suppliers]);

  useEffect(() => {
    if (pinnedLocations.length) {
      getInventories();
    }
  }, [pinnedLocations]);
  return (
    <DashboardTemplate>
      <ListItemContext.Provider
        value={{
          highlightedItem,
          highlightItem,
          clearHighlightItem,
          formatItemId,
          loading,
        }}
      >
        <div className="inventories">
          {response && (
            <ResponseHandler
              response={response}
              deleteMessage={deleteMessage}
            />
          )}
          <div className="columns">
            <div
              className="column is-two-fifths is-marginless list"
              id="inventory-list"
            >
              <MapContext.Provider
                value={{
                  onClickMarket: map.current && map.current.props.onClickMarket,
                }}
              >
                <ListPanel
                  geocoderContainer={geocoderContainer}
                  showInventories={showInventories}
                  setShowInventories={setShowInventories}
                  availableMarkets={availableMarkets}
                  loading={loading}
                  supplier={supplier}
                  supplierSelected={supplierSelected}
                  filters={filters}
                  inventories={inventories}
                  currentPage={currentPage}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  previousFilters={previousFilters}
                  getInventories={getInventories}
                  applyFilters={applyFilters}
                  setPreviousFilters={setPreviousFilters}
                  loadPrevList={loadPrevList}
                  loadNextList={loadNextList}
                />
              </MapContext.Provider>
            </div>
            <div className="column is-three-fifths map is-marginless">
              <InventoryMap
                showInventories={showInventories}
                setShowInventories={setShowInventories}
                loading={loading}
                firebase={firebase}
                items={currentPage}
                clearSearch={clearSearch}
                geocoderContainer={geocoderContainer}
                availableMarkets={availableMarkets}
                currentCoordinates={currentCoordinates}
                setCurrentCoordinates={setCurrentCoordinates}
                selectedMarket={filters.market}
                selectedSuppliers={filters.suppliers}
                setQuickViewItem={setQuickViewItem}
                quickViewItem={quickViewItem}
                getInventories={getInventories}
                map={map}
                pinnedLocations={pinnedLocations}
                setPinnedLocations={setPinnedLocations}
                handleSearchedLocationResult={handleSearchedLocationResult}
              />
            </div>
          </div>
        </div>
      </ListItemContext.Provider>
    </DashboardTemplate>
  );
};

export default Inventories;
