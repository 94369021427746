import React, { PureComponent } from 'react';

import { ListItemContext } from '../../../utils/Contexts/list-item-context';
import { MapContext } from '../../../utils/Contexts/map-context';

import MarketListItem from '../../molecules/MarketListItem/market-list-item';

import './market-list.scss';

class MarketList extends PureComponent {
  render() {
    return (
      <ListItemContext.Consumer>
        {({
          highlightItem,
          clearHighlightItem,
          formatItemId,
          highlightedItem,
        }) => (
          <MapContext.Consumer>
            {({ onClickMarket }) => (
              <div className="market-list-container">
                <p className="market-list-container__title has-text-weight-bold is-size-5">
                  Available Markets
                </p>
                <ul>
                  {this.props.availableMarkets.length
                    ? this.props.availableMarkets.map((availableMarket) => (
                        <li
                          key={availableMarket.displayText}
                          onClick={() =>
                            onClickMarket({
                              coordinates: availableMarket.coordinates,
                            })
                          }
                          onMouseEnter={() =>
                            highlightItem(
                              formatItemId(
                                availableMarket.coordinates.latitude,
                                availableMarket.coordinates.longitude,
                              ),
                            )
                          }
                          onMouseLeave={clearHighlightItem}
                        >
                          <MarketListItem
                            isHovered={
                              highlightedItem ===
                              formatItemId(
                                availableMarket.coordinates.latitude,
                                availableMarket.coordinates.longitude,
                              )
                            }
                            market={availableMarket}
                          />
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            )}
          </MapContext.Consumer>
        )}
      </ListItemContext.Consumer>
    );
  }
}

export default MarketList;
