import React, { Fragment } from 'react';

import InventoryListLoader from './inventory-list-loader';
import InventoryListItem from '../../molecules/InventoryListItem/inventory-list-item';
import PaginationNavFooter from '../../molecules/PaginationNavFooter/pagination-nav-footer';

import './inventory-list.scss';

export default ({
  supplierDocs,
  loading,
  inventories,
  items,
  startIndex,
  endIndex,
  loadPrevList,
  loadNextList,
}) => {
  const fireCrisp = () => {
    if (typeof window !== 'undefined' && window.$crisp) {
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push([
        'set',
        'message:text',
        ['Hi! I’d like to get help regarding my search results.'],
      ]);
    }
  };

  if (loading) return <InventoryListLoader />;

  return (
    <Fragment>
      <div className="inventory-list">
        <ul>
          {items && items.length ? (
            items.map((item) => (
              <li key={item.id}>
                <InventoryListItem
                  id={item.id}
                  data={item.data}
                  supplierDocs={supplierDocs}
                />
              </li>
            ))
          ) : (
            <div className="empty-list">
              <p>
                There are no results for your search query at this time. We are
                always adding new items, so feel free to check back later.
              </p>
              <p>
                In the meantime, we would love to hear your feedback on what you
                are looking for! By letting us know your requirements, you help
                us understand how we can better serve your needs and improve
                Adsight for you. Please click the button below to tell us more.
              </p>
              <div className="empty-list__action">
                {typeof window !== 'undefined' && window.$crisp ? (
                  <button
                    className="button is-link empty-list__button"
                    onClick={fireCrisp}
                  >
                    Tell Us More
                  </button>
                ) : null}
              </div>
            </div>
          )}
        </ul>
      </div>
      {!(loading || inventories.length === 0) ? (
        <PaginationNavFooter
          onPrev={loadPrevList}
          isPrevDisabled={startIndex === 0}
          onNext={loadNextList}
          isNextDisabled={endIndex === inventories.length}
          startIndex={startIndex + 1}
          endIndex={endIndex}
          totalSize={inventories.length}
        />
      ) : null}
    </Fragment>
  );
};
