import React from 'react';

const WorldButton = (props) => {
  const { className, onClick, loading } = props;

  return (
    <div className={`mapboxgl-ctrl mapboxgl-ctrl-group ${className}`}>
      <button
        key="world-button"
        className={`mapboxgl-ctrl-icon mapboxgl-ctrl-world-button`}
        type="button"
        title="Zoom out to markets view"
        onClick={onClick}
        disabled={loading}
      >
        <i className="fas fa-expand-arrows-alt"></i>
      </button>
    </div>
  );
};

export default WorldButton;
