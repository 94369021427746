import React, {
  useState,
  useEffect,
  createRef,
  useContext,
  Fragment,
} from 'react';
import { Marker } from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';

import assetTypes from '../../../models/assetTypes';

import { ListItemContext } from '../../../utils/Contexts/list-item-context';

import MarketPin from '../../atoms/MarketPin/market-pin';
import MapMarkerPin from '../../atoms/MapMarkerPin/map-marker-pin';
import InventoryMapTooltipPopup from '../InventoryMapTooltipPopup/inventory-map-tooltip-popup';

import './inventory-map-markers.scss';

const InventoryMapMarkers = ({
  authUser,
  availableMarkets,
  showInventories,
  loading,
  inventoriesLocation,
  onClickMarket,
  quickViewItem,
  openAssetQuickView,
  viewPort,
  setViewPort,
}) => {
  // const inventoryClusterRef = createRef();
  const marketAreaClusterRef = createRef();
  const listItem = useContext(ListItemContext);
  const [tooltipData, setTooltipData] = useState(undefined);

  //dynamic styling with static definition. Finalization TBD
  const getClusterStyle = (count, isMarket = false) => {
    const markerSize = 20 + 3 * count;
    return {
      width: `${markerSize}px`,
      height: `${markerSize}px`,
      color: '#fff',
      borderRadius: `${markerSize / 2}px`,
      textAlign: 'center',
      lineHeight: `${markerSize}px`,
      fontSize: `${markerSize / 2}px`,
      background: isMarket ? '#333333' : computeClusterColorIntensity(count),
    };
  };

  const computeClusterColorIntensity = (count) => {
    const one = [37, 73, 231];
    const two = [234, 76, 137];
    if (count >= 10) return `rgb(${two.join(' ')})`;
    const gradient = one.map(
      (val, idx) => one[idx] * ((10 - count) / 10) + (two[idx] * count) / 10,
    );
    return `rgb(${gradient.join(' ')})`;
  };

  // inventory cluster implementation details tbd.
  // const inventoryCluster = ({ longitude, latitude, pointCount, clusterId }) => (
  //   <Marker longitude={longitude} latitude={latitude}>
  //     <div
  //       className="inventory-cluster"
  //       onClick={() =>
  //         onClusterClick(inventoryClusterRef, clusterId, longitude, latitude)
  //       }
  //       style={getClusterStyle(pointCount)}
  //     >
  //       {pointCount}
  //     </div>
  //   </Marker>
  // );

  const marketAreaCluster = ({
    longitude,
    latitude,
    pointCount,
    clusterId,
  }) => {
    return (
      <Marker longitude={longitude} latitude={latitude}>
        <div
          className="market-cluster"
          onClick={() =>
            onClusterClick(marketAreaClusterRef, clusterId, longitude, latitude)
          }
          style={getClusterStyle(pointCount, true)}
        >
          {pointCount}
        </div>
      </Marker>
    );
  };

  const onClusterClick = (cluster, clusterId, longitude, latitude) => {
    const supercluster = cluster.current.getCluster();
    const zoom = supercluster.getClusterExpansionZoom(clusterId) + 2;
    setViewPort({
      ...viewPort,
      latitude,
      longitude,
      zoom,
    });
  };

  const focusMarker = (marker) => {
    setTooltipData(marker);
    marker
      ? listItem.highlightItem(`${marker.latitude}_${marker.longitude}`)
      : listItem.clearHighlightItem();
  };

  useEffect(() => {
    if (loading && tooltipData) setTooltipData(undefined);
  }, [loading]);

  if (showInventories) {
    if (inventoriesLocation && inventoriesLocation.length) {
      return (
        <Fragment>
          {authUser && !quickViewItem && tooltipData && tooltipData.pricing ? (
            <InventoryMapTooltipPopup
              coordinates={{
                latitude: tooltipData.latitude,
                longitude: tooltipData.longitude,
              }}
              tooltipText={`from $${tooltipData.pricing} / day`}
            />
          ) : null}
          {/* Implemantation details TBD for inventory clusters */}
          {/* <Cluster
            radius={20}
            extent={512}
            nodeSize={64}
            component={inventoryCluster}
            ref={inventoryClusterRef}
          > */}
          {inventoriesLocation.map((item) => (
            <Marker
              key={`${item.latitude}_${item.longitude}`}
              latitude={item.latitude}
              longitude={item.longitude}
              offsetLeft={-8}
            >
              <MapMarkerPin
                isHovered={
                  listItem.highlightedItem ===
                  listItem.formatItemId(item.latitude, item.longitude)
                }
                isSelected={
                  quickViewItem &&
                  listItem.formatItemId(
                    quickViewItem[0].data.coordinates.latitude,
                    quickViewItem[0].data.coordinates.longitude,
                  ) === listItem.formatItemId(item.latitude, item.longitude)
                }
                inputColor={
                  assetTypes[item.type]
                    ? assetTypes[item.type].selector
                    : 'other'
                }
                size="large"
                onClick={(e) => openAssetQuickView(e, item)}
                onMouseEnter={() => focusMarker(item)}
                onMouseLeave={() => focusMarker(undefined)}
              />
            </Marker>
          ))}
          {/* </Cluster> */}
        </Fragment>
      );
    } else return null;
  } else {
    return (
      <Fragment>
        {tooltipData && tooltipData.type === 'market' ? (
          <InventoryMapTooltipPopup
            coordinates={{
              latitude: tooltipData.latitude,
              longitude: tooltipData.longitude,
            }}
            tooltipText={`Jump to ${tooltipData.marketName}`}
          />
        ) : null}
        <Cluster
          radius={30}
          extent={512}
          nodeSize={64}
          component={marketAreaCluster}
          ref={marketAreaClusterRef}
        >
          {availableMarkets.map(
            (item) =>
              item && (
                <Marker
                  name={item.displayText}
                  key={`${item.coordinates.latitude}_${item.coordinates.longitude}`}
                  latitude={item.coordinates.latitude}
                  longitude={item.coordinates.longitude}
                  offsetLeft={-5.5}
                >
                  <MarketPin
                    isHovered={
                      item &&
                      listItem.highlightedItem ===
                        listItem.formatItemId(
                          item.coordinates.latitude,
                          item.coordinates.longitude,
                        )
                    }
                    onMouseEnter={() =>
                      focusMarker({
                        type: 'market',
                        ...item.coordinates,
                        marketName: item.displayText,
                      })
                    }
                    onMouseLeave={() => focusMarker(undefined)}
                    onClickMarket={() => onClickMarket(item)}
                  />
                </Marker>
              ),
          )}
        </Cluster>
      </Fragment>
    );
  }
};

export default InventoryMapMarkers;
