import React, { PureComponent } from 'react';
import * as _ from 'lodash';

import AdsightPlaceholder from '../../../assets/inventory-placeholder-square.png';

import './market-list-item.scss';
import classNames from 'classnames';
const imageFinder = (marketPicPath) => {
  try {
    const marketPic = require(`../../../assets/markets/${marketPicPath}.jpg`);
    return marketPic;
  } catch (error) {
    return AdsightPlaceholder;
  }
};

class MarketListItem extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      marketPic: imageFinder(
        _.camelCase(`${props.market.city} ${props.market.province}`),
      ),
    };
  }

  render() {
    return (
      <div
        className={classNames('media market-list-item', {
          hovered: this.props.isHovered,
        })}
      >
        <div className="media-left">
          <div className="image is-128x128">
            <img
              className="market-list-item__market-image"
              src={this.state.marketPic}
              alt="img"
            />
          </div>
        </div>
        <div className="media-content market-list-item__content">
          <div className="content">
            <p className="has-text-weight-semibold">
              {this.props.market && this.props.market.displayText}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MarketListItem;
