import React from 'react';

import { useFormik } from 'formik';

import FilterPillsLoader from './fillter-pills-loader';
import FilterPillsForm from '../../molecules/FilterPillsForm/filter-pills-form';

import './filter-pills.scss';

export default ({
  loading,
  supplierDocs,
  supplierSelected,
  supplier,
  filters,
  previousFilters,
  setPreviousFilters,
  applyFilters,
  getInventories,
}) => {
  const formik = useFormik({
    initialValues: {
      ...filters,
      assetTypes: filters.assetTypes || [],
      suppliers: filters.suppliers || [],
      budget: filters.budget,
    },
    onSubmit: (values) => {
      formik.setValues(values);
      setPreviousFilters(values);
      applyFilters(values);
      getInventories();
    },
  });

  const setFieldValue = (field, value) => {
    const toUpdate = {};
    toUpdate[field] = value;
    applyFilters(toUpdate);
    return formik.setFieldValue(field, value);
  };

  if (loading) return <FilterPillsLoader />;
  return (
    <div className="filter-pills-container">
      <FilterPillsForm
        supplierDocs={supplierDocs}
        supplierSelected={supplierSelected}
        supplier={supplier}
        values={formik.values}
        setFieldValue={setFieldValue}
        previousFilters={previousFilters}
        applyFilters={applyFilters}
        onSubmit={formik.handleSubmit}
      />
    </div>
  );
};
