import React from 'react';
import ContentLoader from 'react-content-loader';
import './inventory-list.scss';

const REPEAT_COUNT = 10;
const InventoryListLoader = () => (
  <div className="inventory-list">
    <ul>
      {[...Array(REPEAT_COUNT)].map((n, id) => (
        <li key={id} className="is-paddingless">
          <ContentLoader
            speed={2}
            width={385}
            height={126}
            viewBox="0 0 385 126"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="5" y="5" rx="3" ry="3" width="150" height="120" />
            <rect x="166" y="86" rx="3" ry="3" width="103" height="40" />
            <rect x="166" y="5" rx="3" ry="3" width="191" height="25" />
            <rect x="166" y="40" rx="3" ry="3" width="215" height="10" />
            <rect x="166" y="60" rx="3" ry="3" width="215" height="10" />
            <rect x="276" y="86" rx="3" ry="3" width="40" height="40" />
          </ContentLoader>
        </li>
      ))}
    </ul>
  </div>
);

export default InventoryListLoader;
