import React, { Fragment } from 'react';

import { supplierDocs } from '../../../models/suppliers';

import FilterPills from '../FilterPills/filter-pills';
import InventoryList from '../InventoryList/inventory-list';
import MarketList from '../MarketList/market-list';

import './list-panel.scss';

const ListPanel = ({
  geocoderContainer,
  showInventories,
  availableMarkets,
  loading,
  supplier,
  filters,
  inventories,
  currentPage,
  startIndex,
  endIndex,
  previousFilters,
  getInventories,
  applyFilters,
  setPreviousFilters,
  loadPrevList,
  loadNextList,
}) => {
  return (
    <Fragment>
      <div className="is-size-4 has-text-weight-semibold list-panel__title">
        Discover advertising spaces
      </div>
      <div
        className="list-panel__geocoder-container"
        ref={geocoderContainer}
      ></div>
      {showInventories ? (
        <Fragment>
          <FilterPills
            loading={loading}
            supplierDocs={supplierDocs}
            supplier={supplier}
            applyFilters={applyFilters}
            filters={filters}
            getInventories={getInventories}
            previousFilters={previousFilters}
            setPreviousFilters={setPreviousFilters}
          />
          <InventoryList
            supplierDocs={supplierDocs}
            loading={loading}
            inventories={inventories}
            items={currentPage}
            startIndex={startIndex}
            endIndex={endIndex}
            loadPrevList={loadPrevList}
            loadNextList={loadNextList}
          />
        </Fragment>
      ) : (
        <MarketList availableMarkets={availableMarkets} />
      )}
    </Fragment>
  );
};

export default ListPanel;
