import React, { useContext } from 'react';
import * as NumberAbbrevation from 'number-abbreviate';
import classNames from 'classnames';
import { AuthUserContext } from '../../../utils/Session';
import { ListItemContext } from '../../../utils/Contexts/list-item-context';

import LabeledPin from '../LabeledPin/labeled-pin';
import PlaceholderImage from '../../../assets/inventory-placeholder.png';
import { INVENTORY_DETAILS } from '../../../constants/routes';

import { assetTypes } from '../../../models/assetTypes';
import './inventory-list-item.scss';

const NumbAbbr = new NumberAbbrevation();

export default (props) => {
  const authUser = useContext(AuthUserContext);
  const listItem = useContext(ListItemContext);
  const getName = () => {
    return props.data.address
      ? props.data.address
      : `${assetTypes[props.data.type].displayText} - ${props.data.city}, ${
          props.data.province
        }`;
  };
  const { latitude, longitude } = props.data.coordinates;
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`${INVENTORY_DETAILS}/${props.id}`}
      className={classNames('inventory-item columns', {
        hovered:
          listItem.highlightedItem ===
          listItem.formatItemId(latitude, longitude),
      })}
      onMouseEnter={(e) =>
        listItem.highlightItem(listItem.formatItemId(latitude, longitude))
      }
      onMouseLeave={listItem.clearHighlightItem}
    >
      <div className="column is-one-thirds">
        <img
          className="inventory-img"
          src={props.data.thumbnail || PlaceholderImage}
          alt="inventory-thumbnail"
        />
      </div>
      <div className="column is-two-thirds">
        <div className="inventory-info">
          <h2 className="inventory-header">{getName()}</h2>
          <div className="inventory-description">
            <LabeledPin
              size="small"
              type={props.data.type}
              text={props.data.type}
            />

            {authUser && (
              <p>
                {props.data.pricing && props.data.pricing.dailyRate ? (
                  <span>
                    From ${props.data.pricing.dailyRate}{' '}
                    {props.data.pricing.currency} per day
                  </span>
                ) : null}
              </p>
            )}

            {props.data.averageDailyImpressions ? (
              <p>
                {NumbAbbr.abbreviate(props.data.averageDailyImpressions, 1)}{' '}
                impressions per day
              </p>
            ) : null}

            <p>{props.supplierDocs[props.data.supplier.name].displayText}</p>

            {props.data.city ? <p>{props.data.city}</p> : null}
          </div>
        </div>
      </div>
    </a>
  );
};
