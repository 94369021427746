import React, { useState, useEffect, useContext } from 'react';
import MapGL, {
  NavigationControl,
  Marker,
  Layer,
  Source,
  GeolocateControl,
  MapContext,
} from '@urbica/react-map-gl';
import { distance, circle, featureCollection } from '@turf/turf';
import Geocoder from 'react-map-gl-geocoder';

import { AuthUserContext } from '../../../utils/Session';

import { inventoriesPartitioner } from '../../../utils/helpers/inventories-partitioner';

import AssetTypeLegend from '../AssetTypeLegend/asset-type-legend';
import WorldButton from '../../atoms/WorldButton/world-button';
import InventoryMapPopup from '../../molecules/InventoryMapPopup/inventory-map-popup';
import InventoryMapMarkers from '../../molecules/InventoryMapMarkers/inventory-map-markers';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './inventory-map.scss';

const START_MAP_ZOOM = 3;
const INVENTORY_MIN_ZOOM = 10;
const DEFAULT_RADIUS = 10;
const DEFAULT_TRANSITION_DURATION = 1500;
const REFETCH_DISPLACEMENT_THRESHOLD = 7.5;

const DEFAULT_ACTION = 0;
const DIRECT_ACTION = 1;
const ZOOM_TRANSITION = 2;
const DEFAULT_TRANSITION = 3;

const InventoryMap = ({
  firebase,
  availableMarkets,
  items,
  clearSearch,
  loading,
  currentCoordinates,
  setCurrentCoordinates,
  quickViewItem,
  setQuickViewItem,
  map,
  geocoderContainer,
  getInventories,
  pinnedLocations,
  setPinnedLocations,
  handleSearchedLocationResult,
  showInventories,
  setShowInventories,
}) => {
  const authUser = useContext(AuthUserContext);
  const [viewPort, setViewPort] = useState({
    width: '100%',
    height: 'calc(100vh - 8px)',
    latitude: currentCoordinates.latitude,
    longitude: currentCoordinates.longitude,
    zoom: START_MAP_ZOOM,
    bearing: 0,
    pitch: 0,
    transitionDuration: DEFAULT_TRANSITION_DURATION,
  });
  const [inventoriesLocation, setInventoriesLocation] = useState([]);
  const [inventoriesPartitions, setInventoriesPartitions] = useState({});
  const [mapInTransition, setMapInTransition] = useState(false);
  const [mapActionType, setMapActionType] = useState(DEFAULT_ACTION);
  const [pinnedRadii, setPinnedRadii] = useState(featureCollection([]));
  const [searchRadius, setSearchRadius] = useState();

  const onInteractionStateChange = ({
    isDragging,
    isZooming,
    inTransition,
  }) => {
    if (inTransition) setMapInTransition(true);
    else {
      mapInTransition && setMapInTransition(false);
      if (mapActionType > 1) {
        if (mapActionType === ZOOM_TRANSITION) {
          const newRadius = computeRadius(viewPort.zoom);
          setCurrentCoordinates({
            latitude: viewPort.latitude,
            longitude: viewPort.longitude,
            radius: newRadius,
          });
          setShowInventories(true);
        }
      } else {
        const viewPortZoom = Math.ceil(viewPort.zoom);
        if (viewPortZoom >= INVENTORY_MIN_ZOOM) {
          if (isDragging || isZooming) setMapActionType(DIRECT_ACTION);
          else if (mapActionType === 1) {
            const newRadius = computeRadius(viewPort.zoom);
            const minDistanceForFetch = newRadius >= 7.5 ? 7.5 : newRadius;
            const displacement = distance(
              [currentCoordinates.latitude, currentCoordinates.longitude],
              [viewPort.latitude, viewPort.longitude],
              { units: 'kilometers' },
            );
            if (
              displacement >= minDistanceForFetch ||
              newRadius !== currentCoordinates.radius
            ) {
              setCurrentCoordinates({
                latitude: viewPort.latitude,
                longitude: viewPort.longitude,
                radius: newRadius,
              });
              setShowInventories(true);
            }
          }
        } else {
          if (showInventories) {
            setCurrentCoordinates({
              ...currentCoordinates,
            });
            setShowInventories(false);
            clearSearch();
          }
        }
      }
    }
  };

  const onClickMarket = (item) => {
    firebase.useAnalytics('market_selected', {
      user: authUser ? authUser.email : null,
      selectedMarket: item.displayText,
    });
    moveTo({
      center: [item.coordinates.longitude, item.coordinates.latitude],
      zoom: INVENTORY_MIN_ZOOM,
      isTransition: true,
      showInventories: true,
      transitionDuration: DEFAULT_TRANSITION_DURATION,
      flyToInterpolator: true,
    });
  };

  const onClickWorldButton = () => {
    moveTo({
      center: [viewPort.longitude, viewPort.latitude],
      zoom: START_MAP_ZOOM,
      isTransition: false,
      showInventories: false,
      transitionDuration: DEFAULT_TRANSITION_DURATION,
      flyToInterpolator: true,
    });
    return clearSearch();
  };

  const searchLocation = (viewport) =>
    moveTo({
      center: [viewport.longitude, viewport.latitude],
      zoom: INVENTORY_MIN_ZOOM,
      isTransition: true,
      showInventories: true,
      transitionDuration: DEFAULT_TRANSITION_DURATION,
    });

  const moveTo = ({
    center,
    zoom = INVENTORY_MIN_ZOOM,
    isTransition = false,
    showInventories = true,
    transitionDuration = DEFAULT_TRANSITION_DURATION,
  }) => {
    if (isTransition) {
      setMapActionType(DEFAULT_TRANSITION);
    } else {
      setMapActionType(DEFAULT_ACTION);
    }
    setCurrentCoordinates({
      latitude: center[1],
      longitude: center[0],
      radius: computeRadius(zoom),
    });
    setShowInventories(showInventories);
    setViewPort({
      ...viewPort,
      latitude: center[1],
      longitude: center[0],
      zoom,
      transitionDuration,
    });
  };

  const computeRadius = (zoom) => {
    const zoomLevel =
      DEFAULT_RADIUS * Math.pow(2, INVENTORY_MIN_ZOOM - Math.round(zoom) + 1);

    return zoomLevel >= 1 ? zoomLevel : 1;
  };

  const openAssetQuickView = (e, item) => {
    e.stopPropagation();
    firebase.useAnalytics('map_marker_clicked', {
      user: authUser ? authUser.email : null,
      coordinates: {
        latitude: item.latitude,
        longitude: item.longitude,
      },
    });
    setQuickViewItem([
      ...inventoriesPartitions[`${item.latitude}_${item.longitude}`],
    ]);
  };

  const clearQuickViewItem = () => setQuickViewItem(undefined);

  const openLocationQuickView = (e, pin) => {
    e.stopPropagation();
    setQuickViewItem([
      {
        id: pin.id,
        data: {
          coordinates: {
            latitude: pin.latitude,
            longitude: pin.longitude,
          },
          radius: pin.radius,
        },
      },
    ]);
  };

  const searchLocationResult = ({ result: { place_name } }) => {
    handleSearchedLocationResult(place_name);
    return firebase.useAnalytics('location_searched', {
      user: authUser ? authUser.email : null,
      input: place_name,
    });
  };

  const pinExists = (id) => {
    return pinnedRadii.features.find((pin) => id === pin.properties.id);
  };

  const addPinnedLocation = (point) => {
    firebase.useAnalytics('search_radius_changed', {
      user: authUser ? authUser.email : null,
      radiusValue: searchRadius,
    });
    const newPin = {
      id: point.id,
      latitude: point.data.coordinates.latitude,
      longitude: point.data.coordinates.longitude,
      radius: searchRadius,
    };
    const newCircle = circle(
      [newPin.longitude, newPin.latitude],
      Number(newPin.radius) + 0.5,
      {
        units: 'kilometers',
        properties: { id: newPin.id },
      },
    );
    let newFeatures;
    if (pinExists(point.id)) {
      const pin = pinnedRadii.features.find(
        (pin) => pin.properties.id === point.id,
      );
      newFeatures = pinnedRadii.features
        .slice(0, pinnedRadii.features.indexOf(pin))
        .concat(newCircle)
        .concat(
          pinnedRadii.features.slice(pinnedRadii.features.indexOf(pin) + 1),
        );
    } else {
      newFeatures = pinnedRadii.features.concat(newCircle);
    }
    const newPolygons = { ...pinnedRadii, features: newFeatures };
    setPinnedLocations([...pinnedLocations, newPin]);
    setPinnedRadii(newPolygons);
    clearQuickViewItem();
  };

  const removePin = (id) => {
    setPinnedLocations(pinnedLocations.filter((pin) => id !== pin.id));
    const radii = pinnedRadii.features.filter(
      (pin) => id !== pin.properties.id,
    );
    setPinnedRadii({ ...pinnedRadii, features: radii });
    if (!radii.length) {
      getInventories();
    }
    clearQuickViewItem();
  };

  useEffect(() => {
    if (items && items.length) {
      const data = inventoriesPartitioner(items);
      setInventoriesLocation([...data.ListOfLocations]);
      setInventoriesPartitions({ ...data.partitionedInventories });
    } else {
      setInventoriesLocation([]);
      setInventoriesPartitions({});
    }
  }, [items]);

  useEffect(() => {
    if (mapActionType && mapActionType > DEFAULT_ACTION) {
      getInventories();
      setMapActionType(DEFAULT_ACTION);
    }
  }, [currentCoordinates]);

  return (
    <div>
      {showInventories ? <AssetTypeLegend /> : null}
      <MapGL
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
        accessToken={process.env.GATSBY_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        minZoom={3}
        doubleClickZoom={true}
        ref={map}
        {...viewPort}
        scrollZoom={true}
        moveTo={moveTo}
        onClickMarket={onClickMarket}
        onViewportChange={setViewPort}
        onInteractionStateChange={onInteractionStateChange}
        onClick={clearQuickViewItem}
        dragPan={!mapInTransition}
        viewportChangeMethod={'flyTo'}
      >
        <MapContext.Consumer>
          {/* handle on map's ref object const to add gl-js speciic listeners */}
          {/* TODO: Cleanup onInteractionStateChange && relevant obfuscated logic after simplification */}
          {(map) => {
            map.once('dragend', function (s) {
              const viewportRadius = computeRadius(viewPort.zoom);
              const refetchThreshold =
                viewportRadius >= REFETCH_DISPLACEMENT_THRESHOLD
                  ? REFETCH_DISPLACEMENT_THRESHOLD
                  : viewportRadius;
              const displacement = distance(
                [currentCoordinates.latitude, currentCoordinates.longitude],
                [viewPort.latitude, viewPort.longitude],
                { units: 'kilometers' },
              );
              if (displacement >= refetchThreshold) {
                setMapActionType(DIRECT_ACTION);
                setCurrentCoordinates({
                  latitude: viewPort.latitude,
                  longitude: viewPort.longitude,
                  radius: viewportRadius,
                });
              }
            });

            map.once('zoomend', function (e) {
              const newRadius = computeRadius(viewPort.zoom);
              // listen to explicit events(scroll, doubleClick, navigationControlClick, etc.) only
              if (e.originalEvent) {
                if (viewPort.zoom >= INVENTORY_MIN_ZOOM) {
                  if (!showInventories) setShowInventories(true);
                  if (currentCoordinates.radius !== newRadius) {
                    setMapActionType(ZOOM_TRANSITION);
                    setCurrentCoordinates({
                      latitude: viewPort.latitude,
                      longitude: viewPort.longitude,
                      radius: newRadius,
                    });
                  }
                } else {
                  if (showInventories) setShowInventories(false);
                }
              }
            });
            return;
          }}
        </MapContext.Consumer>
        <div className="location-control">
          <GeolocateControl
            label="Jump to my location"
            position="bottom-left"
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
        </div>
        <div className="navigation-control">
          <NavigationControl showZoom position="bottom-left" />
        </div>
        <div className="world-button">
          <WorldButton onClick={onClickWorldButton} loading={loading} />
        </div>
        <Geocoder
          mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
          mapRef={map}
          containerRef={geocoderContainer}
          position="top-left"
          disable={true}
          placeholder="Search for any city, location, or landmark"
          countries="ca"
          width="100%"
          onViewportChange={searchLocation}
          onResult={searchLocationResult}
        />
        <InventoryMapPopup
          authUser={authUser}
          quickViewItem={quickViewItem}
          searchRadius={searchRadius}
          clearQuickViewItem={clearQuickViewItem}
          setSearchRadius={setSearchRadius}
          addPinnedLocation={addPinnedLocation}
          pinExists={pinExists}
          removePin={removePin}
        />
        <InventoryMapMarkers
          authUser={authUser}
          showInventories={showInventories}
          availableMarkets={availableMarkets}
          loading={loading}
          inventoriesLocation={inventoriesLocation}
          onClickMarket={onClickMarket}
          quickViewItem={quickViewItem}
          openAssetQuickView={openAssetQuickView}
          viewPort={viewPort}
          setViewPort={setViewPort}
        />
        {pinnedLocations &&
          pinnedLocations.map((pin, idx) => (
            <Marker
              key={idx}
              className="pinned-location-marker"
              latitude={pin.latitude}
              longitude={pin.longitude}
              offsetLeft={-16}
              offsetTop={-16}
            >
              <div onClick={(e) => openLocationQuickView(e, pin)}>
                <i className="fas fa-map-pin"></i>
              </div>
            </Marker>
          ))}
        <Source id="pinnedRadii" type="geojson" data={pinnedRadii} />
        <Layer
          id="pinnedRadii"
          type="fill"
          source="pinnedRadii"
          paint={{
            'fill-color': 'pink',
            'fill-opacity': 0.4,
          }}
        />
        {loading && (
          <i className="fas fa-2x fa-spin fa-circle-notch loading-indicator" />
        )}
      </MapGL>
    </div>
  );
};

export default InventoryMap;
