import React from 'react';

import { Popup } from '@urbica/react-map-gl';

import './inventory-map-tooltip-popup.scss';

const InventoryMapTooltipPopup = ({ coordinates, tooltipText }) => {
  return (
    <Popup
      className="tooltip-popup"
      closeButton={false}
      closeOnClick={false}
      latitude={coordinates.latitude}
      longitude={coordinates.longitude}
      maxWidth="none"
    >
      {tooltipText}
    </Popup>
  );
};

export default InventoryMapTooltipPopup;
