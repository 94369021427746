import React, { useContext } from 'react';

import './market-pin.scss';
import classNames from 'classnames';
import { ListItemContext } from '../../../utils/Contexts/list-item-context';

export default ({ onClickMarket, isHovered, ...props }) => {
  const listItem = useContext(ListItemContext);
  return (
    <div onClick={listItem.loading ? null : onClickMarket}>
      <i
        className={classNames('market-pin fas fa-map-marker-alt', {
          'is-loading': listItem.loading,
          hovered: isHovered,
        })}
        {...props}
      />
    </div>
  );
};
