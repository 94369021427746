export const inventoriesPartitioner = (inventories) => {
  let partitionedInventories = {};
  let ListOfLocations = [];
  inventories.reduce((accumulator, currentValue) => {
    if (
      accumulator[
        `${currentValue.data.coordinates.latitude}_${currentValue.data.coordinates.longitude}`
      ]
    ) {
      accumulator[
        `${currentValue.data.coordinates.latitude}_${currentValue.data.coordinates.longitude}`
      ].push({ ...currentValue });
    } else {
      accumulator[
        `${currentValue.data.coordinates.latitude}_${currentValue.data.coordinates.longitude}`
      ] = [{ ...currentValue }];
    }
    return accumulator;
  }, partitionedInventories);

  for (const property in partitionedInventories) {
    const location = property.split('_');
    let lowerPrice = 0;

    partitionedInventories[property].forEach((item) => {
      if (lowerPrice === 0) {
        lowerPrice = item.data.pricing.dailyRate;
      } else if (item.data.pricing.dailyRate < lowerPrice) {
        lowerPrice = item.data.pricing.dailyRate;
      }
    });
    ListOfLocations.push({
      latitude: parseFloat(location[0]),
      longitude: parseFloat(location[1]),
      type: partitionedInventories[property][0].data.type,
      pricing: lowerPrice,
    });
  }

  return {
    partitionedInventories,
    ListOfLocations,
  };
};
