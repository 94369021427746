import React from 'react';

//TODO: rename & refactor to inventoryContext to centralize context state
export const ListItemContext = React.createContext({
  highlightedItem: undefined,
  highlightItem: () => {},
  clearHighlightItem: () => {},
  formatItemId: () => {},
  loading: false,
});
