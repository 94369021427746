/**
 * model for market areas with key as <city>_<province>
 * TODO: Migrate to Firestore once data model is complete in db
 */
export const marketAreas = {
  abbotsfordBc: {
    city: 'Abbotsford',
    province: 'BC',
    displayText: 'Abbotsford, BC',
    coordinates: {
      latitude: 49.050131,
      longitude: -122.193568,
    },
    radius: 10,
  },
  bellevilleOn: {
    city: 'Belleville',
    province: 'ON',
    displayText: 'Belleville, ON',
    coordinates: {
      latitude: 44.188117,
      longitude: -77.396028,
    },
  },
  brantfordOn: {
    city: 'Brantford',
    province: 'ON',
    displayText: 'Brantford, ON',
    coordinates: {
      latitude: 43.150906,
      longitude: -80.279783,
    },
    radius: 10,
  },
  brossardQc: {
    city: 'Brossard',
    province: 'QC',
    displayText: 'Brossard, QC',
    coordinates: {
      latitude: 45.455151,
      longitude: -73.465439,
    },
    radius: 10,
  },
  cambridgeOn: {
    city: 'Cambridge',
    province: 'ON',
    displayText: 'Cambridge, ON',
    coordinates: {
      latitude: 43.373102,
      longitude: -80.319183,
    },
    radius: 10,
  },
  calgaryAb: {
    city: 'Calgary',
    province: 'AB',
    displayText: 'Calgary, AB',
    coordinates: {
      latitude: 51.0580574,
      longitude: -114.1214829,
    },
    radius: 10,
  },
  chaseBc: {
    city: 'Chase',
    province: 'BC',
    displayText: 'Chase, BC',
    coordinates: {
      latitude: 50.766893,
      longitude: -119.731109,
    },
    radius: 10,
  },
  cranbrookBc: {
    city: 'Cranbrook',
    province: 'BC',
    displayText: 'Cranbrook, BC',
    coordinates: {
      latitude: 49.509724,
      longitude: -115.76667,
    },
    radius: 10,
  },
  dawsonCreekBc: {
    city: 'Dawson Creek',
    province: 'BC',
    displayText: 'Dawson Creek, BC',
    coordinates: {
      latitude: 55.8021,
      longitude: -120.31617,
    },
    radius: 10,
  },
  edmontonAb: {
    city: 'Edmonton',
    province: 'AB',
    displayText: 'Edmonton, AB',
    coordinates: {
      latitude: 53.5177982,
      longitude: -113.4956806,
    },
    radius: 10,
  },
  enderbyBc: {
    city: 'Enderby',
    province: 'BC',
    displayText: 'Enderby, BC',
    coordinates: {
      latitude: 50.50614,
      longitude: -119.13803,
    },
    radius: 10,
  },
  fortMcMurrayAb: {
    city: 'Fort McMurray',
    province: 'AB',
    displayText: 'Fort McMurray, AB',
    coordinates: {
      latitude: 56.73082,
      longitude: -111.437382,
    },
    radius: 10,
  },
  fortStJohnBc: {
    city: 'Fort St. John',
    province: 'BC',
    displayText: 'Fort St. John, BC',
    coordinates: {
      latitude: 56.246464,
      longitude: -120.847633,
    },
    radius: 10,
  },
  fraserlakeBc: {
    city: 'Fraser Lake',
    province: 'BC',
    displayText: 'Fraser Lake, BC',
    coordinates: {
      latitude: 54.062582,
      longitude: -124.458442,
    },
    radius: 10,
  },
  guelphOn: {
    city: 'Guelph',
    province: 'ON',
    displayText: 'Guelph, ON',
    coordinates: {
      latitude: 43.61039,
      longitude: -80.308804,
    },
    radius: 10,
  },
  greaterHamiltonOn: {
    city: 'Greater Hamilton',
    province: 'ON',
    displayText: 'Greater Hamilton, ON',
    coordinates: {
      latitude: 43.235292,
      longitude: -79.714625,
    },
    radius: 10,
  },
  hagersvilleOn: {
    city: 'Hagersville',
    province: 'ON',
    displayText: 'Hagersville, ON',
    coordinates: {
      latitude: 42.963174,
      longitude: -80.050867,
    },
    radius: 10,
  },
  hamiltonOn: {
    city: 'Hamilton',
    province: 'ON',
    displayText: 'Hamilton, ON',
    coordinates: {
      latitude: 43.259201,
      longitude: -79.887412,
    },
    radius: 10,
  },
  hopeBc: {
    city: 'Hope',
    province: 'BC',
    displayText: 'Hope, BC',
    coordinates: {
      latitude: 49.394594,
      longitude: -121.458442,
    },
    radius: 10,
  },
  jasperAb: {
    city: 'Jasper',
    province: 'AB',
    displayText: 'Jasper, AB',
    coordinates: {
      latitude: 52.884876,
      longitude: -118.072128,
    },
    radius: 10,
  },
  kamloopsBc: {
    city: 'Kamloops',
    province: 'BC',
    displayText: 'Kamloops, BC',
    coordinates: {
      latitude: 50.714843,
      longitude: -120.340491,
    },
    radius: 10,
  },
  kelownaBc: {
    city: 'kelowna',
    province: 'BC',
    displayText: 'Kelowna, BC',
    coordinates: {
      latitude: 49.828686,
      longitude: -119.624859,
    },
    radius: 10,
  },
  kitchenerOn: {
    city: 'Kitchener',
    province: 'ON',
    displayText: 'Kitchener, ON',
    coordinates: {
      latitude: 43.404811,
      longitude: -80.449862,
    },
    radius: 10,
  },
  kootenayBc: {
    city: 'Kootenay',
    province: 'BC',
    displayText: 'Kootenay, BC',
    coordinates: {
      latitude: 49.03733,
      longitude: -118.86872,
    },
    radius: 10,
  },
  laidlawBc: {
    city: 'Laidlaw',
    province: 'BC',
    displayText: 'Laidlaw, BC',
    coordinates: {
      latitude: 49.335694,
      longitude: -121.603925,
    },
    radius: 10,
  },
  londonOn: {
    city: 'London',
    province: 'ON',
    displayText: 'London, ON',
    coordinates: {
      latitude: 42.940722,
      longitude: -81.254443,
    },
    radius: 10,
  },
  malakwaBc: {
    city: 'Malakwa',
    province: 'BC',
    displayText: 'Malakwa, BC',
    coordinates: {
      latitude: 50.930628,
      longitude: -118.793648,
    },
    radius: 10,
  },
  markhamOn: {
    city: 'Markham',
    province: 'ON',
    displayText: 'Markham, ON',
    coordinates: {
      latitude: 43.856098,
      longitude: -79.337021,
    },
    radius: 10,
  },
  mississaugaOn: {
    city: 'Mississauga',
    province: 'ON',
    displayText: 'Mississauga, ON',
    coordinates: {
      latitude: 43.64839,
      longitude: -79.60954,
    },
    radius: 10,
  },
  montrealQc: {
    city: 'Montreal',
    province: 'QC',
    displayText: 'Montreal, QC',
    coordinates: {
      latitude: 45.5057506,
      longitude: -73.5762873,
    },
    radius: 10,
  },
  muskokaOn: {
    city: 'Muskoka',
    province: 'ON',
    displayText: 'Muskoka, ON',
    coordinates: {
      latitude: 45.041544,
      longitude: -79.773036,
    },
    radius: 10,
  },
  newmarketOn: {
    city: 'Newmarket',
    province: 'ON',
    displayText: 'Newmarket, ON',
    coordinates: {
      latitude: 44.059189,
      longitude: -79.461258,
    },
    radius: 10,
  },
  niagaraFallsOn: {
    city: 'Niagara Falls',
    province: 'ON',
    displayText: 'Niagara Falls, ON',
    coordinates: {
      latitude: 43.092122,
      longitude: -79.076534,
    },
    radius: 10,
  },
  northYorkOn: {
    city: 'North York',
    province: 'ON',
    displayText: 'North York, ON',
    coordinates: {
      latitude: 43.761538,
      longitude: -79.411079,
    },
    radius: 10,
  },
  oliverBc: {
    city: 'Oliver',
    province: 'BC',
    displayText: 'Oliver, BC',
    coordinates: {
      latitude: 49.182326,
      longitude: -119.550428,
    },
    radius: 10,
  },
  ottawaOn: {
    city: 'Ottawa',
    province: 'ON',
    displayText: 'Ottawa, ON',
    coordinates: {
      latitude: 45.425814,
      longitude: -75.690761,
    },
    radius: 10,
  },
  parisOn: {
    city: 'Paris',
    province: 'ON',
    displayText: 'Paris, ON',
    coordinates: {
      latitude: -80.346114,
      longitude: 43.18391,
    },
    radius: 10,
  },
  princeGeorgeBc: {
    city: 'Prince George',
    province: 'BC',
    displayText: 'Prince George, BC',
    coordinates: {
      latitude: 53.83033,
      longitude: -122.67219,
    },
    radius: 10,
  },
  princeRupertBc: {
    city: 'Prince Rupert',
    province: 'BC',
    displayText: 'Prince Rupert, BC',
    coordinates: {
      latitude: 54.312195,
      longitude: -130.327087,
    },
    radius: 10,
  },
  quebecQc: {
    city: 'Quebec',
    province: 'QC',
    displayText: 'Quebec, QC',
    coordinates: {
      latitude: 46.829853,
      longitude: -71.254028,
    },
    radius: 10,
  },
  quesnelBc: {
    city: 'Quesnel',
    province: 'BC',
    displayText: 'Quesnel, BC',
    coordinates: {
      latitude: 52.875095,
      longitude: -122.435252,
    },
    radius: 10,
  },
  salmonArmBc: {
    city: 'Salmon Arm',
    province: 'BC',
    displayText: 'Salmon Arm, BC',
    coordinates: {
      latitude: 50.708042,
      longitude: -119.325305,
    },
    radius: 10,
  },
  savonaBc: {
    city: 'Savona',
    province: 'BC',
    displayText: 'Savona, BC',
    coordinates: {
      latitude: 50.755945,
      longitude: -120.893301,
    },
    radius: 10,
  },
  scarboroughOn: {
    city: 'Scarborough',
    province: 'ON',
    displayText: 'Scarborough, ON',
    coordinates: {
      latitude: 43.777702,
      longitude: -79.233238,
    },
    radius: 10,
  },
  sicamousBc: {
    city: 'Sicamous',
    province: 'BC',
    displayText: 'Sicamous, BC',
    coordinates: {
      latitude: 50.837824,
      longitude: -118.976816,
    },
    radius: 10,
  },
  smithersBc: {
    city: 'Smithers',
    province: 'BC',
    displayText: 'Smithers, BC',
    coordinates: {
      latitude: 54.80472,
      longitude: -127.19907,
    },
    radius: 10,
  },
  sudburyOn: {
    city: 'Sudbury',
    province: 'ON',
    displayText: 'Sudbury, ON',
    coordinates: {
      latitude: 46.45175,
      longitude: -81.00426,
    },
    radius: 10,
  },
  thunderBayOn: {
    city: 'Thunder Bay',
    province: 'ON',
    displayText: 'Thunder Bay, ON',
    coordinates: {
      latitude: 48.378651,
      longitude: -89.299729,
    },
    radius: 10,
  },
  torontoOn: {
    city: 'Toronto',
    province: 'ON',
    displayText: 'Toronto, ON',
    coordinates: {
      latitude: 43.7606893,
      longitude: -79.531149,
    },
    radius: 10,
  },
  vancouverBc: {
    city: 'Vancouver',
    province: 'BC',
    displayText: 'Vancouver, BC',
    coordinates: {
      latitude: 49.2830377,
      longitude: -123.1240368,
    },
    radius: 10,
  },
  vanderhoofBc: {
    city: 'Vanderhoof',
    province: 'BC',
    displayText: 'Vanderhoof, BC',
    coordinates: {
      latitude: 53.82615,
      longitude: -123.11071,
    },
    radius: 10,
  },
  vaughanOn: {
    city: 'Vaughan',
    province: 'ON',
    displayText: 'Vaughan, ON',
    coordinates: {
      latitude: 43.837208,
      longitude: -79.508278,
    },
  },
  vernonBc: {
    city: 'Vernon',
    province: 'BC',
    displayText: 'Vernon, BC',
    coordinates: {
      latitude: 50.27179,
      longitude: -119.276505,
    },
    radius: 10,
  },
  wellandOn: {
    city: 'Welland',
    province: 'ON',
    displayText: 'Welland, ON',
    coordinates: {
      latitude: 42.992332,
      longitude: -79.249109,
    },
    radius: 10,
  },
  westmountQc: {
    city: 'Westmount',
    province: 'QC',
    displayText: 'Westmount, QC',
    coordinates: {
      latitude: 45.484531,
      longitude: -73.597023,
    },
    radius: 10,
  },
  windsorOn: {
    city: 'Windsor',
    province: 'ON',
    displayText: 'Windsor, ON',
    coordinates: {
      latitude: 42.317343,
      longitude: -83.038567,
    },
    radius: 10,
  },
  winnipegMb: {
    city: 'Winnipeg',
    province: 'MB',
    displayText: 'Winnipeg, MB',
    coordinates: {
      latitude: 49.895763,
      longitude: -97.139262,
    },
    radius: 10,
  },
};

export default marketAreas;
