import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { marketAreas } from '../../../models/marketAreas';
import './filter-pill.scss';

export default ({
  firebase,
  authUser,
  buttonTitle,
  dropDownID,
  children,
  clear,
  onSubmit,
  isSelected,
  type,
  value,
  prevFilter,
  map,
}) => {
  const node = useRef();
  const [open, setOpen] = useState(false);

  const formatButtonText = () => {
    if (type === 'radio') {
      return marketAreas[prevFilter].displayText;
    } else if (type === 'price') {
      return `$${prevFilter} CAD`;
    }
    return buttonTitle;
  };

  const onMouseDown = (e) => {
    if (!node.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const save = (e) => {
    firebase.useAnalytics(`${dropDownID}_filter_changed`, {
      user: authUser && authUser !== true ? authUser.email : null,
      value,
    });
    onSubmit(e);
    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  return (
    <div
      ref={node}
      className={classNames('dropdown', {
        'is-active': open,
        'is-disabled': !authUser,
      })}
    >
      <div className="dropdown-trigger">
        <button
          type="button"
          className={classNames('button is-rounded is-small pill-button', {
            'is-selected': isSelected,
          })}
          disabled={!authUser}
          aria-haspopup="true"
          aria-controls={dropDownID}
          onClick={(e) => setOpen(!open)}
        >
          {isSelected && prevFilter ? formatButtonText() : buttonTitle}
        </button>
      </div>
      <div className="dropdown-menu pill-content-container" id={dropDownID}>
        <div className="pill-content-container__main">{children}</div>
        <div className="pill-content-container__action buttons are-small">
          <button
            className="button is-danger is-inverted"
            type="button"
            onClick={clear}
          >
            {' '}
            Clear
          </button>
          <button className="button is-primary" type="button" onClick={save}>
            {' '}
            Save{' '}
          </button>
        </div>
      </div>
    </div>
  );
};
