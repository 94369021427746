import React from 'react';
import { Popup } from '@urbica/react-map-gl';

import AssetQuick from '../../molecules/AssetQuick/asset-quick';
import Button from '../../atoms/Button/button';

import './inventory-map-popup.scss';

const InventoryMapPopup = ({
  authUser,
  quickViewItem,
  searchRadius,
  clearQuickViewItem,
  setSearchRadius,
  addPinnedLocation,
  pinExists,
  removePin,
}) => {
  if (!quickViewItem) return null;

  return (
    <Popup
      className="quick-view-item"
      anchor="bottom"
      onClose={clearQuickViewItem}
      closeOnClick={false}
      latitude={quickViewItem[0].data.coordinates.latitude}
      longitude={quickViewItem[0].data.coordinates.longitude}
      maxWidth="none"
    >
      {!quickViewItem[0].data.radius && (
        <div>
          <AssetQuick authUser={authUser} quickViewItem={quickViewItem} />
          <hr />
        </div>
      )}
      <div className="radius-search">
        <h2>Search Radius (km): </h2>
        <div className="actions">
          <input
            type="number"
            name="radius"
            id="search-radius"
            placeholder="1~50 km"
            min="1"
            max="50"
            onChange={(e) => setSearchRadius(e.target.value)}
          />
          <div className="buttons are-small">
            <Button
              text="Search"
              className="button is-primary"
              onClick={() => addPinnedLocation(quickViewItem[0])}
              disabled={!searchRadius || searchRadius < 1 || searchRadius > 50}
            />
            <Button
              text="Clear"
              icon="fa-times"
              className="button is-danger is-inverted"
              onClick={() => removePin(quickViewItem[0].id)}
              disabled={!pinExists(quickViewItem[0].id)}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default InventoryMapPopup;
